jQuery(function($){
	// single carousel
	$('.item-carousel').slick({
		autoplay: true,
		arrows: false,
		dots: true
	});

	// carousel with multiple items
	$('.item-multicarousel').slick({
		autoplay: true,
	  infinite: true,
	  slidesToShow: 7,
	  slidesToScroll: 1,
	  centerPadding: '60px',
	  responsive: [
	    {
	      breakpoint: 768,
	      settings: {
	        arrows: false,
	        centerMode: true,
	        centerPadding: '40px',
	        slidesToShow: 3
	      }
	    },
	    {
	      breakpoint: 480,
	      settings: 'unslick'
	    }
	  ]
	});
});
