window.onload = function() {
	if (typeof(document.querySelector( ".btn-collapse" )) != 'undefined' && (document.querySelector( ".btn-collapse" )) != null) {
		var collapselink = document.querySelector( ".btn-collapse" );
	  collapselink.addEventListener( "click", function() {
	    this.classList.toggle( "active" );
			if(this.classList.contains('active')) {
				this.innerHTML = "Hide";
			} else {
				this.innerHTML = "Show all";
			}
		});
	};
}
